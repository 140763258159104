import axios from "axios";
// config
import { HOST_API } from "../config";

// // ----------------------------------------------------------------------

// const axiosInstance = axios.create({
//   baseURL: HOST_API,
//   withCredentials: true,
//   crossDomain: true,
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

// export default axiosInstance;

const axiosInstance = axios.create({
  baseURL: HOST_API,
  withCredentials: true,
  crossDomain: true,
});

// Add a request interceptor to include `x-user-id`
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = localStorage.getItem("_id"); // Retrieve x-user-id from localStorage
    const orgId = JSON.parse(localStorage.getItem("currentOrganization"))?._id;
    if (userId) {
      config.headers["x-user-id"] = userId; // Attach the header
    }
    if (orgId) {
      config.headers["x-org-id"] = orgId; // Attach the header
    }
    return config;
  },
  (error) => Promise.reject(error) // Handle errors
);

// Add a response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
