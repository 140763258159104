import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { List, Typography, ListItemButton } from "@mui/material";

import CustomPopover, {
  usePopover,
} from "src/components/customized/custom-popover";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

export default function OrganizationSwitcher() {
  const { organizations, setCurrentOrganization, currentOrganization } =
    useAuth();

  const popover = usePopover();

  const [orgAccounts, setOrgAccounts] = useState([]);

  const handleBusinessChange = (orgId) => {
    popover.onClose();
    setCurrentOrganization(orgId);
  };

  useEffect(() => {
    localStorage.getItem("currentOrganization") &&
      setCurrentOrganization(
        JSON.parse(localStorage.getItem("currentOrganization"))
      );
    const orgAccounts = organizations?.filter(
      (org) => org?._id !== currentOrganization?._id
    );
    setOrgAccounts(orgAccounts);
  }, [currentOrganization?._id, organizations, setCurrentOrganization]);

  return (
    <>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: "grey.200", py: 0, borderRadius: 1 }}
      >
        <ListItemButton
          id="lock-button"
          disabled={orgAccounts?.length === 0}
          onClick={popover.onOpen}
          sx={{
            p: 0.5,
            width: 225,
            borderRadius: 1,
            "&.Mui-disabled": { opacity: 1 },
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              src={currentOrganization?.avatarUrl}
              alt={currentOrganization?.name}
              sx={{
                width: 42,
                height: 42,
                border: (theme) =>
                  `solid 2px ${theme.palette.background.default}`,
                borderRadius: 1,
              }}
            />
            <Stack>
              <Typography
                variant="subtitle2"
                flexWrap="wrap"
                sx={{ color: "text.primary" }}
              >
                {currentOrganization?.name}
              </Typography>
            </Stack>
          </Stack>
        </ListItemButton>
      </List>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 225, p: 0, ml: 0 }}
      >
        <List sx={{ py: 0 }}>
          {orgAccounts?.map((org) => (
            <ListItemButton
              key={org._id}
              id="lock-button"
              onClick={() => handleBusinessChange(org)}
              sx={{ p: 0.5, width: 225 }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar
                  src={org.avatarUrl}
                  alt={org.name}
                  sx={{
                    width: 42,
                    height: 42,
                    border: (theme) =>
                      `solid 2px ${theme.palette.background.default}`,
                    borderRadius: 1,
                  }}
                />
                <Stack>
                  <Typography variant="subtitle2" noWrap>
                    {org.name}
                  </Typography>
                </Stack>
              </Stack>
            </ListItemButton>
          ))}
        </List>
      </CustomPopover>
    </>
  );
}
