// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import getBotTriggers from "src/services/chatReopts/getBotTriggers";

// Initial State
const initialState = {
  bot_triggers: [],
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { bot_triggers } = action.payload;
    return { ...state, bot_triggers, loading: false };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const ChatBotContext = createContext();

// Provider Component
export const ChatBotProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async (date_range) => {
    try {
      const { bot_triggers } = await getBotTriggers(date_range);

      dispatch({ type: "INITIALIZE", payload: { bot_triggers } });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ChatBotContext.Provider
      value={{
        bot_triggers: state.bot_triggers,
        loading: state.loading,
        initialize,
      }}
    >
      <Outlet />
      {/* {children} */}
    </ChatBotContext.Provider>
  );
};
