import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// Config
import { USER_GLOBAL_HOST_API_KEY } from "src/config";
// @mui
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { PATH_AUTH } from "src/routes/paths";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import VerifyOtpForm from "../VerifyOtpForm";
import Iconify from "../../../components/Iconify";
import ForgotPwdOtpForm from "../ForgotPwdOtpForm";
import ResetPasswordForm from "../ResetPasswordForm";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const url = new URL(window.location.href);
  const hostUrl = url.origin;

  const { login } = useAuth();

  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // const defaultValues = {
  //   email: "demo@minimals.cc",
  //   password: "demo1234",
  //   remember: true,
  // };
  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error.error });
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOtpVerified(false);
    setRequestSent(false);
    setOpen(false);
  };

  const [requestSent, setRequestSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpVerifiedToken, setOtpVerifiedToken] = useState("");

  const handleRequestSent = () => {
    setRequestSent(!requestSent);
  };

  const handleOtpVerified = (token) => {
    setOtpVerifiedToken(token);
    setOtpVerified(!otpVerified);
  };

  const handleRegisterRoute = () => {
    navigate(PATH_AUTH.register);
  };

  const handleRedirect = () => {
    window.open(
      `${USER_GLOBAL_HOST_API_KEY}/auth/google?redirectDomain=${hostUrl}/auth/google`,
      "_self"
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="forgot-password-dialog"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="forgot-password-dialog">
          {"Forgot password?"}
        </DialogTitle>
        <DialogContent sx={{ pb: 0, mt: 2 }}>
          {!requestSent ? (
            <ForgotPwdOtpForm requestStatus={handleRequestSent} />
          ) : !otpVerified ? (
            <VerifyOtpForm otpStatus={handleOtpVerified} />
          ) : (
            <ResetPasswordForm token={otpVerifiedToken} />
          )}
        </DialogContent>
        <DialogActions>
          {/* {requestSent && !otpVerified && (
            <Button onClick={handleClose}>Back</Button>
          )} */}
          <Button onClick={handleClose}>cancel</Button>
        </DialogActions>
      </Dialog>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
          <Button onClick={handleClickOpen}>Forgot password?</Button>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
        <Typography sx={{ textAlign: "center", mt: 2 }}>
          Not registered?{" "}
          <Link
            variant="body2"
            color="primary"
            underline="always"
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={handleRegisterRoute}
          >
            Create an account
          </Link>
        </Typography>
      </FormProvider>

      <Typography
        variant="subtitle2"
        sx={{ mt: 3, mb: 1.5, textAlign: "center", color: "text.secondary" }}
      >
        or
      </Typography>
      <LoadingButton
        variant="outlined"
        startIcon={<Iconify icon="flat-color-icons:google" />}
        sx={{
          textTransform: "none",
          fontWeight: 600,
          color: "primary.main",
          borderColor: "#d9d9d9",
          "&:hover": {
            backgroundColor: "#f7f7f7",
            borderColor: "#d9d9d9",
          },
          borderRadius: "8px",
          px: 2,
          py: 1,
          maxWidth: "max-content",
          alignSelf: "center",
        }}
        onClick={handleRedirect}
      >
        Sign in with Google
      </LoadingButton>
    </>
  );
}
