import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography } from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
//
import AccountPopover from "./AccountPopover";
import { keyframes } from "@emotion/react";
import useAuth from "src/hooks/useAuth";
// import Searchbar from './Searchbar';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

const scrollLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const { currentOrganization } = useAuth();
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const hostname = window.location.hostname;

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
          borderBottom: "1px solid #ececec",
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {/* <Searchbar /> */}
        <Typography variant="h5" sx={{ color: "#212B36" }}>
          {currentOrganization?.name}
        </Typography>

        {/* {hostname === "admin.emovur.com" ? (
          <Box
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "flex",
              width: "70%",
              alignItems: "center",
              justifyContent: "center",
              ml: "40px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#EF5A6F",
                animation: `${scrollLeft} 30s linear infinite`, // Reduced duration for faster restart
              }}
            >
              From 5th November, you will be able to send messages only if you
              have active subscription and wallet balance. If your paid balance
              and subscription is not available in your account, please reach
              out to your relationship manager.
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "flex",
              width: "70%",
              alignItems: "center",
              justifyContent: "center",
              ml: "40px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#EF5A6F",
                animation: `${scrollLeft} 30s linear infinite`, // Reduced duration for faster restart
              }}
            >
              From 5th November, your customers will be able to send messages
              only if they have active subscription and wallet balance.
              Meanwhile partner wallet balance also should have positive
              balance.
            </Typography>
          </Box>
        )} */}

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover />
          <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
