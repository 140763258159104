// utils

import { USER_GLOBAL_HOST_API_KEY } from "src/config";
import axiosInstance from "src/utils/axios";

const getUserData = async (userId) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `${USER_GLOBAL_HOST_API_KEY}/users/${userId}`,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { getUserData };
