import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography, Stack, Link, CircularProgress } from "@mui/material";

// hooks
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
//
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import { getUserData } from "src/services/register/getUserData";
import { setSession } from "src/utils/jwt";
import { getAllOrganizationsOfUser } from "src/services/register/getAllOrganizationsOfUser";
import useAuth from "src/hooks/useAuth";
import LoadingScreen from "src/components/LoadingScreen";

// ----------------------------------------------------------------------

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  minHeight: `calc(100vh - ${HEADER.MOBILE_HEIGHT + 24}px)`,
  paddingBottom: 0,
  [theme.breakpoints.up("lg")]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: 0,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    minHeight: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { organizations, setOrganizations, setCurrentOrganization } = useAuth();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === "vertical";

  // const FooterStyle  = styled(Box)(({ theme }) => ({
  //   position: 'fixed',
  //   bottom: 0,
  //   width: '100%',
  //   zIndex: theme.zIndex.appBar,
  //   backgroundColor: theme.palette.background.default,
  // }));
  let storedLogo = localStorage.getItem("landingLogo");
  let favIcon = localStorage.getItem("faviconLogo");
  const currentOrganization = JSON.parse(
    localStorage.getItem("currentOrganization")
  );

  const [landingLogo, setLandingLogo] = useState(storedLogo);
  const [faviconLogo, setFaviconLogo] = useState(favIcon);

  const ORGANIZATION_STORAGE_KEY = "currentOrganization";

  useEffect(() => {
    if (storedLogo && storedLogo !== "null") {
      setLandingLogo(storedLogo);
    }
    if (favIcon && favIcon !== "null") {
      setFaviconLogo(favIcon);
    }
  }, [favIcon, storedLogo]);

  const [loading, setLoading] = useState(true);

  const handleProfile = async () => {
    const _id = localStorage.getItem("_id");
    const userData = await getUserData(_id);
    setSession(userData, _id);
  };

  useEffect(() => {
    const storedOrg = localStorage.getItem(ORGANIZATION_STORAGE_KEY);
    if (storedOrg) {
      setLoading(false);
      handleProfile();
      return;
    }

    const fetchOrganizations = async () => {
      try {
        const data = await getAllOrganizationsOfUser();
        setOrganizations(data);

        if (data.length === 1) {
          localStorage.setItem(
            ORGANIZATION_STORAGE_KEY,
            JSON.stringify(data[0])
          );
          handleProfile();
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, [setOrganizations]);

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
        />
        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        )}
        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 2 },
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
      />
      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        landingLogo={landingLogo}
        emovurIcon={faviconLogo}
        currentOrganization={currentOrganization}
      />
      <MainStyle collapseClick={collapseClick}>
        <Stack
          justifyContent={"space-between"}
          sx={{
            minHeight: `calc(100vh - ${HEADER.MOBILE_HEIGHT + 25}px)`,
            height: "100%",
            pb: "50px",
          }}
        >
          <Outlet />
        </Stack>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            backgroundColor: "#fff",
            width: {
              lg: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
              md: `100%`,
              sm: `100%`,
              xs: `100%`,
            },
            // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,

            ...(isCollapse && {
              width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
            }),
            opacity: 0.95,
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              borderTop: "1px solid #ececec",
            }}
            variant="body2"
          >
            Powered by
            <Link rel="noopener" target="_blank" sx={{ ml: 1 }}>
              <img
                src={landingLogo}
                alt="logo"
                width={80}
                style={{ margin: 0 }}
              />
            </Link>
          </Typography>
        </Box>
      </MainStyle>
    </Box>
  );
}
