import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// Organisation Data Providers
import { OrgUnitProvider } from "../contexts/organisation/OrgUnitContext";
import { UsersProvider } from "src/contexts/organisation/users/UsersContext";
import { CustomFieldsProvider } from "src/contexts/organisation/customFields/CustomFieldsContext";
import { CustomFieldValuesProvider } from "src/contexts/organisation/customFields/CustomFieldValuesContext";

// Social post data providers
import { SocialPostProvider } from "src/contexts/socialPost/SocialPostContext";
import { LinkedInContextProvider } from "src/contexts/socialPost/LinkedInContext";

// Chat app data providers
import { ChatReportContextProvider } from "src/contexts/chatApp/ChatReportContext";
import { ChatSettingsProvider } from "src/contexts/chatApp/ChatSettingsContext";
import { ChatTokenProvider } from "src/contexts/chatApp/ChatTokenContext";
import { NewChatAppSettingsProvider } from "src/contexts/chatApp/NewChatAppSettingsContext";

// Gupshup and Whatsapp data providers
import { GupshupSettingsProvider } from "src/contexts/whatsApp/WhatsAppSettingsContext";
import { WhatsAppTemplateProvider } from "src/contexts/whatsApp/WhatsAppTemplateContext";
import { DialogflowContextProvider } from "src/contexts/dialogflow/DialogflowContext";
import { BroadcastJourneyProvider } from "src/contexts/whatsApp/BroadcastJourneyContext";
import { CreateTemplateProvider } from "src/contexts/whatsApp/CreateTemplateContext";
import { ReportSettingsProvider } from "src/contexts/whatsApp/ReportSettingsContext";

// GMB and Business messages data providers
import { GmbLocationProvider } from "src/contexts/googleMyBusiness/GmbLocationContext";
import { GmbLocAccProvider } from "src/contexts/googleMyBusiness/GmbLocAccContext";
import { GeoGridContextProvider } from "src/contexts/googleMyBusiness/GeoGridContext";
import { ReviewLocationsContextProvider } from "src/contexts/googleMyBusiness/reviews/ReviewLocationsContext";
import { LocationReviewsContextProvider } from "src/contexts/googleMyBusiness/reviews/LocationReviewsContext";
import { CannedResponsesContextProvider } from "src/contexts/googleMyBusiness/reviews/CannedResponsesContext";
import { UpdateProfileProvider } from "src/contexts/googleMyBusiness/businessProfile/UpdateProfile";
import { UnsubscribersListProvider } from "src/contexts/whatsApp/UnsubscribersListContext";
import { TwitterContextProvider } from "src/contexts/socialPost/TwitterContext";
import { GoogleAdsProvider } from "src/contexts/googleAds/GoogleAdsContext";
import { GoogleMapsApiKeysProvider } from "src/contexts/googleMyBusiness/GoogleMapsApiKeysContext";
import { ChatBotProvider } from "src/contexts/chatApp/ChatBotContext";
import { CreateFlowProvider } from "src/contexts/whatsApp/WhatsAppFlowContext";
import { WhatsAppFlowTableProvider } from "src/contexts/whatsApp/WhatsAppFlowTableContext";
import CreateThirdPartyConnector from "src/pages/whatsapp/CreateThirdPartyConnector";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/manage-account/:accessToken",
      element: <ManageAccount />,
    },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "google",
          element: (
            <GuestGuard>
              <GoogleSignInCallback />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        // <Navigate to="/dashboard/whatsapp/broadcast/create-broadcast" replace />
        <Navigate to={PATH_AFTER_LOGIN} replace />
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "org-selector",
          element: <OrgSelector />,
        },
        {
          element: <GupshupSettingsProvider />,
          children: [
            {
              path: "whatsapp/home",
              element: <Home />,
            },
          ],
        },
        {
          path: "organisation",
          children: [
            {
              element: <OrgUnitProvider />,
              children: [
                { path: "units", element: <OrgUnit /> },
                {
                  element: <UsersProvider />,
                  path: "users",
                  children: [
                    {
                      path: "list",
                      element: <UserList />,
                    },
                    {
                      path: "create-user",
                      element: <UserCreate />,
                    },
                    {
                      path: ":name/edit",
                      element: <UserCreate />,
                    },
                  ],
                },
              ],
            },
            {
              element: <CustomFieldsProvider />,
              children: [
                {
                  element: <CustomFieldValuesProvider />,
                  children: [
                    { path: "custom-fields", element: <CustomFields /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "social-post",
          children: [
            {
              element: <SocialPostProvider />,
              children: [
                {
                  element: <LinkedInContextProvider />,
                  children: [
                    {
                      element: <TwitterContextProvider />,
                      children: [
                        {
                          element: <GmbLocAccProvider />,
                          children: [
                            {
                              path: "new-post",
                              element: <NewPost />,
                            },
                          ],
                        },
                        {
                          path: "settings",
                          element: <Settings />,
                        },
                        {
                          path: "settings/:social",
                          element: <Settings />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            // {
            //   path: "all-posts",
            //   element: <AllPosts />,
            //   children: [
            //     {
            //       path: "post-links",
            //       element: <PostLinks />,
            //     },
            //   ],
            // },
            {
              path: "all-posts",
              children: [
                { element: <AllPosts />, index: true },
                {
                  path: "post-links",
                  element: <PostLinks />,
                },
              ],
            },
            {
              path: "analytics",
              element: <Analytics />,
            },
            {
              path: "analytics/:postName/:id",
              element: <IndividualAnalytics />,
            },
          ],
        },
        {
          element: <ChatTokenProvider />,
          children: [
            {
              element: <NewChatAppSettingsProvider />,
              children: [
                {
                  path: "chat-app",
                  children: [
                    {
                      element: <ChatBotProvider />,
                      children: [
                        {
                          path: "bot",
                          children: [
                            { element: <Bot />, index: true },
                            {
                              path: "create-trigger",
                              element: <CreateTrigger />,
                            },

                            {
                              path: "edit-trigger",
                              element: <CreateTrigger />,
                            },
                          ],
                        },
                      ],
                    },
                    { path: "settings", element: <ChatAppSettings /> },
                    {
                      element: <ChatReportContextProvider />,
                      children: [{ path: "report", element: <ChatReport /> }],
                    },
                  ],
                },
                {
                  path: "google-my-business",
                  element: <ChatSettingsProvider />,
                  children: [
                    {
                      path: "settings",
                      children: [
                        {
                          path: "general",
                          element: <GmbSettings />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          element: <GupshupSettingsProvider />,
          children: [
            {
              element: <WhatsAppTemplateProvider />,
              children: [
                {
                  path: "whatsapp",
                  children: [
                    {
                      path: "e-commerce",
                      children: [
                        {
                          path: "catalogue",
                          element: <Catalogue />,
                        },
                        {
                          path: "orders",
                          element: <Orders />,
                        },
                        {
                          path: "payments-setup",
                          element: <PaymentsSetup />,
                        },
                      ],
                    },
                    {
                      path: "account",
                      children: [
                        {
                          element: <ChatTokenProvider />,
                          children: [
                            {
                              element: <OrgUnitProvider />,
                              children: [
                                {
                                  element: <UsersProvider />,
                                  children: [
                                    {
                                      element: <ChatTokenProvider />,
                                      children: [
                                        {
                                          element: (
                                            <NewChatAppSettingsProvider />
                                          ),
                                          children: [
                                            {
                                              element: <ChatBotProvider />,
                                              children: [
                                                {
                                                  path: "setup",
                                                  element: <GupshupSettings />,
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      path: "settings",
                                      children: [
                                        {
                                          path: "profile",
                                          element: <WhatsappProfile />,
                                        },

                                        {
                                          element: <ReportSettingsProvider />,
                                          children: [
                                            {
                                              path: "config-report",
                                              element: (
                                                <WhatsAppReportSettings />
                                              ),
                                            },
                                          ],
                                        },
                                        {
                                          path: "unsubscribers-list",
                                          element: (
                                            <UnsubscribersListProvider />
                                          ),
                                          children: [
                                            {
                                              element: <UnsubscribersList />,
                                              index: true,
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },

                        {
                          path: "billing",
                          children: [
                            {
                              path: "billing-profile",
                              element: <BillProfile />,
                            },
                            {
                              path: "wallet",
                              element: <Wallet />,
                            },
                            {
                              path: "invoice",
                              element: <DownloadInvoice />,
                            },
                            {
                              path: "payment-methods",
                              element: <BillingPayments />,
                            },
                            {
                              path: "subscription",
                              element: <Subscription />,
                            },
                          ],
                        },
                        {
                          path: "developers",
                          children: [
                            {
                              path: "api-logs",
                              element: <ApiLogs />,
                            },
                            {
                              path: "forward-webhooks",
                              element: <ForwardWebhooks />,
                            },
                            {
                              path: "thrid-party-connectors",
                              element: <ThirdPartyConnectors />,
                            },
                            {
                              path: "thrid-party-connectors/create-connector",
                              element: <CreateThirdPartyConnector />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "bot",
                      element: <ChatBotProvider />,
                      children: [
                        { element: <Bot />, index: true },
                        {
                          path: "create-trigger",
                          element: <CreateTrigger />,
                        },
                        {
                          path: "create-trigger-default",
                          element: <CreateBotDefultResponses />,
                        },

                        {
                          path: "edit-trigger",
                          element: <CreateTrigger />,
                        },
                        {
                          path: "edit-trigger-default",
                          element: <CreateBotDefultResponses />,
                        },
                      ],
                    },
                    {
                      path: "team-inbox",
                      children: [
                        {
                          element: <TeamInbox />,
                          index: true,
                        },
                        {
                          path: "report",
                          element: <ChatReport />,
                        },
                      ],
                    },
                    {
                      path: "whatsapp-templates",
                      element: <GupshupTemplates />,
                    },
                    {
                      element: <CreateTemplateProvider />,
                      children: [
                        {
                          path: "whatsapp-templates/create-template",
                          element: <CreateTemplate />,
                        },
                      ],
                    },
                    {
                      element: <WhatsAppFlowTableProvider />,
                      children: [
                        {
                          path: "whatsapp-flows",
                          element: <Flows />,
                        },
                        {
                          path: "whatsapp-flows/create-responses",
                          element: <CreateWhatsappFlowsResponses />,
                        },
                      ],
                    },
                    {
                      path: "whatsapp-contacts",
                      element: <Contacts />,
                    },
                    {
                      path: "insights",
                      element: <InsightsMsgLimit />,
                    },
                    {
                      element: <CreateFlowProvider />,
                      children: [
                        {
                          path: "whatsapp-flows/create-flow",
                          element: <CreateWhatsappFlows />,
                        },

                        {
                          path: "whatsapp-flows/create-flow/flow-content",
                          element: <CreateWhatsappFlowsJSONForm />,
                        },
                        {
                          path: "whatsapp-flows/edit-flow/flow-content",
                          element: <CreateWhatsappFlowsJSONForm />,
                        },
                      ],
                    },
                    {
                      path: "insights",
                      element: <InsightsMsgLimit />,
                    },
                    {
                      path: "integration",
                      children: [
                        { element: <Integration />, index: true },
                        {
                          path: "create-webhook",
                          element: <CreateWebhook />,
                        },
                        {
                          path: "edit-webhook",
                          element: <CreateWebhook />,
                        },
                      ],
                    },
                    {
                      path: "broadcast",
                      children: [
                        {
                          path: "create-broadcast",
                          element: <WhatsAppBroadcastMessage />,
                        },
                        {
                          path: "broadcast-report",
                          element: <BroadcastScheduleList />,
                        },
                        {
                          path: "broadcast-labels",
                          element: <BroadcastLabels />,
                        },
                        {
                          path: "broadcast-labels/create-label",
                          element: <CreateBroadcastLabel />,
                        },
                        {
                          path: "broadcast-labels/edit-label",
                          element: <CreateBroadcastLabel />,
                        },
                        {
                          element: <BroadcastJourneyProvider />,
                          children: [
                            {
                              path: "broadcast-journey",
                              children: [
                                {
                                  element: <BroadcastJourneyList />,
                                  index: true,
                                },
                                {
                                  path: "create-journey",
                                  element: <NewEditJourney />,
                                },
                                {
                                  path: "edit/:id/:name",
                                  element: <NewEditJourney />,
                                },
                                {
                                  path: "initiate/:id/:name",
                                  element: <InitiateJourney />,
                                },
                                {
                                  path: "initiate/history/:id/:name",
                                  element: <JourneyHistory />,
                                },
                                {
                                  path: "new-broadcast/:id/:journeyName",
                                  element: <NewEditBroadcast />,
                                },
                                {
                                  path: "broadcast/:id/:journeyName/:broadcastId/:broadcastName",
                                  element: <NewEditBroadcast />,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      element: <DialogflowContextProvider />,
                      children: [
                        {
                          path: "dialogflow",
                          children: [
                            {
                              path: "analytics",
                              element: <DialogflowAnalytics />,
                            },
                            {
                              path: "manage-intents",
                              element: <DialogflowIntents />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "crm",
          children: [
            {
              path: "overview",
              element: <Overview />,
            },
          ],
        },
        {
          path: "google-my-business",
          children: [
            {
              path: "reviews",
              children: [
                {
                  element: <ReviewLocationsContextProvider />,
                  children: [
                    {
                      element: <Reviews />,
                      index: true,
                    },
                  ],
                },
                {
                  element: <LocationReviewsContextProvider />,
                  children: [
                    {
                      path: ":locId/:locName",
                      element: <LocationReviews />,
                    },
                    {
                      path: ":locId/:locName/analytics",
                      element: <LocationReviewsAnalytics />,
                    },
                  ],
                },
                {
                  element: <CannedResponsesContextProvider />,
                  children: [
                    {
                      path: "canned-responses",
                      element: <CannedResponses />,
                    },
                  ],
                },
              ],
            },
            {
              path: "business-messages-analytics",
              children: [
                {
                  element: <BusinessMsgsReport />,
                  index: true,
                },
              ],
            },
            {
              element: <GeoGridContextProvider />,
              children: [
                {
                  path: "geo-grid",
                  element: <GeoGrid />,
                },
                {
                  path: "geo-grid",
                  children: [
                    {
                      element: <GmbLocAccProvider />,
                      children: [
                        {
                          path: "new-report-schedule",
                          element: <ScheduleReport />,
                        },
                      ],
                    },
                    {
                      path: ":id",
                      element: <GeoGridReportDetails />,
                    },
                    // {
                    //   path: ":id/:locName/:locId/:repId/:type/:keyId/:key",
                    //   element: <LocationReport />,
                    // },
                    {
                      path: "location-report",
                      element: <LocationReport />,
                    },
                  ],
                },
              ],
            },
            {
              path: "settings",
              element: <ChatSettingsProvider />,
              children: [
                {
                  element: <UsersProvider />,
                  children: [
                    {
                      path: "general",
                      element: <GmbSettings />,
                    },
                    {
                      element: <GoogleMapsApiKeysProvider />,
                      children: [
                        {
                          path: "google-maps-api-keys",
                          element: <GoogleMapsApiKeys />,
                        },
                      ],
                    },
                    {
                      element: <ReportSettingsProvider />,
                      children: [
                        {
                          path: "gmb/report-settings",
                          element: <GmbReportSettings />,
                        },
                        {
                          path: "geogrid/report-settings",
                          element: <GeogridReportSettings />,
                        },
                        {
                          path: "chat/report-settings",
                          element: <ChatReportSettings />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              element: <OrgUnitProvider />,
              children: [
                {
                  element: <GmbLocAccProvider />,
                  children: [
                    {
                      element: <UsersProvider />,
                      children: [
                        {
                          path: "settings",
                          children: [
                            {
                              path: "manage-locations",
                              element: <ManageLocations />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  element: <UsersProvider />,
                  children: [
                    {
                      element: <ChatSettingsProvider />,
                      children: [
                        {
                          element: <GmbLocationProvider />,
                          children: [
                            { path: "locations", element: <Locations /> },
                            { path: "add-location", element: <AddLocation /> },
                            { path: ":name/edit", element: <AddLocation /> },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              element: <GmbLocAccProvider />,
              children: [
                {
                  element: <UpdateProfileProvider />,
                  children: [
                    {
                      path: "profile-info",
                      children: [
                        { element: <UpdateProfile />, index: true },
                        {
                          path: "profile-update-history",
                          element: <ProfileUpdateHistory />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "add-update",
                  children: [
                    {
                      element: <AddUpdate />,
                      index: true,
                    },
                    {
                      path: "update-history",
                      element: <UpdateHistory />,
                    },
                  ],
                },
                {
                  path: "upload-photos",
                  children: [
                    {
                      element: <UploadPhotos />,
                      index: true,
                    },
                    {
                      path: "upload-history",
                      element: <UploadHistory />,
                    },
                    {
                      path: "locations-list",
                      element: <LocationsList />,
                    },
                  ],
                },
                {
                  path: "services",
                  children: [
                    {
                      element: <Services />,
                      index: true,
                    },
                    {
                      path: "update-history",
                      element: <ServicesLogs />,
                    },
                  ],
                },
                {
                  path: "schema-info",
                  children: [
                    {
                      element: <SchemaInfo />,
                      index: true,
                    },
                    {
                      path: "generate-schema",
                      element: <SchemaJson />,
                    },
                  ],
                },
                // {
                //   path: "google-workspace",
                //   children: [
                //     {
                //       element: <GoogleWorkspace />,
                //       index: true,
                //     },
                //     {
                //       path: "info",
                //       element: <CustomerInfo />,
                //     },
                //     { path: "info/edit", element: <EditCustomerInfo /> },
                //   ],
                // },
              ],
            },
          ],
        },
        {
          path: "google-workspace",
          children: [
            {
              element: <GoogleWorkspace />,
              index: true,
            },
            {
              path: "info",
              element: <CustomerInfo />,
            },
            { path: "info/edit", element: <EditCustomerInfo /> },
          ],
        },
        {
          element: <GoogleAdsProvider />,
          children: [
            {
              path: "google-ads",
              children: [
                { path: "overview", element: <GoogleAds /> },
                { path: "settings", element: <AdsSettings /> },
              ],
            },
          ],
        },
        {
          path: "assets-manager",
          children: [
            {
              path: "domain-setup",
              element: <DomainSetup />,
            },
            {
              path: "url-manager/manage-tags",
              element: <Tags />,
            },
            {
              path: "url-manager",
              element: <UrlManager />,
            },
            {
              path: "media-manager",
              element: <MediaManager />,
            },
          ],
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "org-profile",
          element: <OrgProfile />,
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("src/pages/auth/Login")));
const Register = Loadable(lazy(() => import("src/pages/register/Register")));
const GoogleSignInCallback = Loadable(
  lazy(() => import("src/pages/auth/google/GoogleSignInCallback"))
);

// Dashboard

// General
const Home = Loadable(lazy(() => import("src/pages/Home")));
const OrgSelector = Loadable(lazy(() => import("src/pages/OrgSelector")));
const Profile = Loadable(lazy(() => import("src/pages/Profile")));
const OrgProfile = Loadable(lazy(() => import("src/pages/OrgProfile")));

// Organisation
const OrgUnit = Loadable(lazy(() => import("src/pages/organisation/OrgUnit")));
const UserList = Loadable(
  lazy(() => import("src/pages/organisation/users/UserList"))
);
const UserCreate = Loadable(
  lazy(() => import("src/pages/organisation/users/UserCreate"))
);
const CustomFields = Loadable(
  lazy(() => import("src/pages/organisation/CustomFields"))
);

// Social Posts
const NewPost = Loadable(lazy(() => import("src/pages/socialPost/NewPost")));
const AllPosts = Loadable(lazy(() => import("src/pages/socialPost/AllPosts")));
const Analytics = Loadable(
  lazy(() => import("src/pages/socialPost/Analytics"))
);
const PostLinks = Loadable(
  lazy(() => import("src/pages/socialPost/PostLinks"))
);
const IndividualAnalytics = Loadable(
  lazy(() => import("src/pages/socialPost/IndividualAnalytics"))
);
const Settings = Loadable(
  lazy(() => import("src/pages/socialPost/settings/Settings"))
);

// ChatApp
const ChatAppSettings = Loadable(
  lazy(() => import("src/pages/chatApp/ChatAppSettings"))
);
const Bot = Loadable(lazy(() => import("src/pages/chatApp/Bot")));
const CreateTrigger = Loadable(
  lazy(() => import("src/pages/chatApp/CreateTrigger"))
);
const CreateBotDefultResponses = Loadable(
  lazy(() => import("src/pages/chatApp/CreateBotDefultResponses"))
);
const ChatReport = Loadable(lazy(() => import("src/pages/chatApp/ChatReport")));

// WhatsApp
const GupshupSettings = Loadable(
  lazy(() => import("src/pages/whatsapp/settings/GeneralSettings"))
);
const WhatsAppReportSettings = Loadable(
  lazy(() => import("src/pages/whatsapp/settings/ReportSettings"))
);
const WhatsappProfile = Loadable(
  lazy(() => import("src/pages/whatsapp/Profile"))
);
const GupshupTemplates = Loadable(
  lazy(() => import("src/pages/whatsapp/Templates"))
);
const Flows = Loadable(lazy(() => import("src/pages/whatsapp/Flows")));
const Contacts = Loadable(
  lazy(() => import("src/pages/whatsapp/contects/Contacts"))
);
const Catalogue = Loadable(lazy(() => import("src/pages/whatsapp/Catalogue")));
const Orders = Loadable(lazy(() => import("src/pages/whatsapp/Orders")));
const PaymentsSetup = Loadable(
  lazy(() => import("src/pages/whatsapp/orders/PaymentsSetup"))
);
const WhatsAppBroadcastMessage = Loadable(
  lazy(() => import("src/pages/whatsapp/MessageBroadcast"))
);
const BroadcastScheduleList = Loadable(
  lazy(() => import("src/pages/whatsapp/BroadcastsList"))
);
const BroadcastLabels = Loadable(
  lazy(() => import("src/pages/whatsapp/broadcast/BroadcastLabels"))
);
const CreateBroadcastLabel = Loadable(
  lazy(() =>
    import("src/sections/@dashboard/whatsapp/broadcast/CreateBroadcastLabel")
  )
);
const BroadcastJourneyList = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/BroadcastJourneyList"))
);
const NewEditJourney = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/NewEditJourney"))
);
const InitiateJourney = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/InitiateJourney"))
);
const JourneyHistory = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/JourneyHistory"))
);
const NewEditBroadcast = Loadable(
  lazy(() => import("src/pages/whatsapp/broadastJourney/NewEditBroadcast"))
);
const DialogflowIntents = Loadable(
  lazy(() => import("src/pages/whatsapp/DialogflowIntents"))
);
const DialogflowAnalytics = Loadable(
  lazy(() => import("src/pages/whatsapp/DialogflowAnalytics"))
);
const UnsubscribersList = Loadable(
  lazy(() => import("src/pages/whatsapp/UnsubscribersList"))
);
const Wallet = Loadable(lazy(() => import("src/pages/whatsapp/Wallet")));
const DownloadInvoice = Loadable(
  lazy(() => import("src/sections/@dashboard/whatsapp/billing/DownloadInvoice"))
);

const BillProfile = Loadable(
  lazy(() => import("src/pages/whatsapp/billing/Profile.js"))
);
const BillingPayments = Loadable(
  lazy(() => import("src/pages/whatsapp/BillingPayments"))
);
const Subscription = Loadable(
  lazy(() => import("src/pages/whatsapp/subscription/Subscription"))
);
const TeamInbox = Loadable(
  lazy(() => import("src/pages/whatsapp/teamInbox/TeamInbox"))
);
const ApiLogs = Loadable(lazy(() => import("src/pages/whatsapp/ApiLogs")));
const ForwardWebhooks = Loadable(
  lazy(() => import("src/pages/whatsapp/ForwordWebhooks"))
);
const ThirdPartyConnectors = Loadable(
  lazy(() => import("src/pages/whatsapp/ThirdPartyConnectors"))
);

const CreateTemplate = Loadable(
  lazy(() => import("src/pages/whatsapp/CreateTemplate"))
);
const InsightsMsgLimit = Loadable(
  lazy(() => import("src/pages/whatsapp/Insights"))
);
const CreateWhatsappFlows = Loadable(
  lazy(() => import("src/pages/whatsapp/CreateFlows"))
);
const CreateWhatsappFlowsJSONForm = Loadable(
  lazy(() => import("src/pages/whatsapp/CreateFlowsJSONForm"))
);
const CreateWhatsappFlowsResponses = Loadable(
  lazy(() => import("src/pages/whatsapp/CreateResponses"))
);
const Integration = Loadable(
  lazy(() => import("src/pages/whatsapp/integration/Integration"))
);
const CreateWebhook = Loadable(
  lazy(() => import("src/pages/whatsapp/integration/CreateWebhook"))
);

// CRM
const Overview = Loadable(lazy(() => import("src/pages/crm/Overview")));

// Google My Business
const Locations = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/businessMessages/Locations"))
);
const AddLocation = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/businessMessages/AddLocation"))
);
const GmbSettings = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/GmbSettings"))
);
const GoogleMapsApiKeys = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/GoogleMapsApiKeys"))
);
const Reviews = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/ReviewLocations"))
);
const CannedResponses = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/CannedResponses"))
);
const LocationReviews = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/reviews/LocationReviews"))
);
const LocationReviewsAnalytics = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/reviews/LocationReviewsAnalytics")
  )
);
const ManageLocations = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/ManageLocations"))
);
const BusinessMsgsReport = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/businessMessages/BusinessMsgsReport")
  )
);
const GeoGrid = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/GeoGrid"))
);
const ScheduleReport = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/ScheduleReport"))
);
const GeoGridReportDetails = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/GeoGridReportDetails"))
);
const LocationReport = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/groGrid/LocationReport"))
);
const UpdateProfile = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/updateProfile/UpdateProfile"))
);
const ProfileUpdateHistory = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/updateProfile/ProfileUpdateHistory")
  )
);
const UploadPhotos = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/UploadMedia"))
);
const UploadHistory = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/UploadHistory"))
);
const AddUpdate = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/addUpdate/AddUpdate"))
);
const UpdateHistory = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/addUpdate/UpdateHistory"))
);
const LocationsList = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/uploadMedia/LocationsList"))
);
const Services = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/services/Services"))
);
const SchemaInfo = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/schema/SchemaInfo"))
);
const SchemaJson = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/schema/SchemaJson"))
);
const ServicesLogs = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/services/ServicesLogs"))
);
const GmbReportSettings = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/GmbReportSettings"))
);
const GeogridReportSettings = Loadable(
  lazy(() =>
    import("src/pages/googleMyBusiness/settings/GeogridReportSettings")
  )
);
const ChatReportSettings = Loadable(
  lazy(() => import("src/pages/googleMyBusiness/settings/ChatReportSettings"))
);
const GoogleWorkspace = Loadable(
  lazy(() => import("src/pages/googleWorkspace/GWorkspace"))
);
const CustomerInfo = Loadable(
  lazy(() => import("src/pages/googleWorkspace/CustomerInfo"))
);
const EditCustomerInfo = Loadable(
  lazy(() => import("src/pages/googleWorkspace/EditCustomerInfo"))
);

// Google Ads
const GoogleAds = Loadable(lazy(() => import("src/pages/googleAds/GoogleAds")));
const AdsOverview = Loadable(
  lazy(() => import("src/pages/googleAds/AdsOverview"))
);
const AdsSettings = Loadable(
  lazy(() => import("src/pages/googleAds/AdsSettings"))
);

// Assets Manager
const DomainSetup = Loadable(
  lazy(() => import("src/pages/assetsManager/DomainSetup"))
);
const Tags = Loadable(lazy(() => import("src/pages/assetsManager/Tags")));
const UrlManager = Loadable(
  lazy(() => import("src/pages/assetsManager/UrlManager"))
);
const MediaManager = Loadable(
  lazy(() => import("src/pages/assetsManager/MediaManager"))
);

// Misc pages
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));

// Manage Account
const ManageAccount = Loadable(lazy(() => import("src/pages/ManageAccount")));
